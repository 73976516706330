import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/Projects/ProjectCard";
import Particle from "../components/Particle";
import nike from "../assets/projects/nike.png";
import crypto from "../assets/projects/crypto.png";
import visualVerse from "../assets/projects/visualVerse.png";
import abstractify from "../assets/projects/abstractify.png";
import kyc from "../assets/projects/KYC.png";
import nft from '../assets/projects/nft.png'

const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent Top <strong className="yellow">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={visualVerse}
              isBlog={false}
              title="VisualVerse"
              description="VisualVerse stands as a testament to creative innovation that harnesses the formidable capabilities of OpenAI's DALL-E API. This innovative platform empowers users to generate captivating, high-quality images simply by providing text descriptions. With an intuitive user interface built using Node.js, React.js, and Vite, VisualVerse allows users to transform their textual descriptions into visually stunning AI-generated imagery.
              Dive into a world where words come to life through the lens of artificial intelligence."
              ghLink="https://github.com/sahirooh/VisualVerse"
              demoLink="https://visualverse.netlify.app/"
            />
          </Col>  

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={abstractify}
              isBlog={false}
              title="Abstractify"
              description="Abstractify, is an innovative article summarizer powered by OpenAI's API. This project, meticulously crafted with React, Redux, JavaScript, and Tailwind CSS, offers a seamless solution to condense lengthy articles swiftly. By harnessing the power of RapidAPI and cutting-edge technology, Abstractify simplifies complex content, allowing users to grasp the essence of any article efficiently. Experience the future of information processing with this user-friendly and efficient tool."
              ghLink="https://github.com/sahirooh/Abstractify"
              demoLink="https://abstractify-ai.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kyc}
              isBlog={false}
              title="KYC-Chain"
              description="KYC checks are currently an extremely time consuming and costly affair. Through KYC chain we aim to simplify this process to a great extent. KYC-chain eliminates the redundant KYC checks that banks currently perform by maintaining a common secure database in a block-chain. The nature of a block-chain ensures that unauthorized changes to the data are automatically invalidated. The proof-of-reputation concept makes the verification process more robust."
              ghLink="https://github.com/sahirooh/KYC-Portal"
              demoLink="https://kyc-portal.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={crypto}
              isBlog={false}
              title="Crypto Tracker"
              description="Track the ever-evolving world of cryptocurrencies with our comprehensive app. Stay informed about the top 100 blockchain projects, their real-time rates, and explore insightful charts powered by Chart.js. Safeguard your portfolio and access personalized features with our secure user database integration using Firebase. Embrace the future of finance and make informed decisions with our powerful crypto tracker app"
              ghLink="https://github.com/sahirooh/Crypto_Tracker"
              demoLink="https://cryptotracker-up.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nike}
              isBlog={false}
              title="Nike"
              description="Nike Landing Page is a sleek and dynamic website crafted with React, Vite, and Tailwind CSS. Experience the essence of Nike's brand through our intuitive design, showcasing their iconic products. Seamlessly blending style and functionality, this landing page offers an immersive glimpse into Nike's world, captivating visitors with smooth interactions and captivating visuals. Explore Nike like never before."
              ghLink="https://github.com/sahirooh/Nike"
              demoLink="https://nike-in.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nft}
              isBlog={false}
              title="An-FT Marketplace"
              description="NFT Marketplace revolutionizes digital asset trading with a cutting-edge platform built on Solidity, Hardhat, and Ethers. Explore a diverse array of unique, blockchain-based assets from artists and creators worldwide. Our user-friendly interface ensures seamless browsing, buying, and selling of NFTs. Dive into the future of digital art and collectibles, where creativity meets technology, all in one innovative marketplace."
              ghLink="https://github.com/sahirooh/An-FT_Marketplace"
              demoLink="https://an-ft-marketplace.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Projects